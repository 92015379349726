<template>
  <el-container>
    <el-aside style="width: auto">
      <SideMenu></SideMenu>
    </el-aside>
    <el-container>
      <el-header>
        <strong style="font-size: 1.3em">爱乐移动广告管理后台</strong>
        <div class="header-avatar">
          <!--          <el-avatar size="medium"-->
          <!--                     src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>-->
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userinfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link :to='{name: "userInfo"}'>个人中心</router-link>
              </el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
<!--          <el-link href="http://strategy.adtianmai.com/admin" target="_blank">旧平台地址</el-link>-->
        </div>
      </el-header>
      <el-main>
        <tabs></tabs>
        <div style="margin: 0 20px">
          <router-view></router-view>
        </div>

      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import SideMenu from "@/views/inc/SideMenu";
import Tabs from "@/views/inc/Tabs";

export default {
  name: "Home",
  components: {SideMenu, Tabs},
  methods: {
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    getUserInfo() {
      this.$axios.get("/sys/userInfo").then(res => {
        this.userinfo.username = res.data.data.username;
        this.userinfo.id = res.data.data.id;
        //设置用户信息
        this.$store.commit("setUserInfo", this.userinfo)
      })
    },
    logout() {
      this.$axios.post("/logout").then(res => {
        localStorage.clear()
        sessionStorage.clear()
        this.$store.commit("resetState")
        this.$router.push("/login")
      })
    }
  },
  comments: {
    SideMenu
  },
  created() {
    this.getUserInfo();
    // this.$router.push("/index")
  },
  data() {
    return {
      userinfo: {
        id: "",
        username: "",
      }
    }
  }
}
</script>

<style scoped>
.el-container {
  padding: 0;
  margin: 0;
  height: 100%;
}

.header-avatar {
  float: right;
  width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.el-header {
  background-color: #FAFAFA;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  line-height: 200px;
  text-align: left;
}

.el-main {
  color: #333;
  padding: 0;
}

.el-input.is-disabled .el-input__inner,textarea:disabled{
    color: #606266 !important;
  }

</style>